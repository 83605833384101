import { createGenerateClassName, StylesProvider } from '@material-ui/core';
import AuthenticatedArea from './components/AuthenticatedArea';
import { EnvConfig, EnvProvider } from './components/EnvProvider';
import Router from './components/Router/Router';
import './global.css';
import { IntlProvider } from './i18n';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-oneask-portal-mfe',
    seed: 'bees-hub-oneask-portal-mfe',
  });

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <AuthenticatedArea>
            <Router />
          </AuthenticatedArea>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
