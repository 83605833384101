import httpClient, { getAuthHeader } from './client';

export const isAuthenticated = () => !!getAuthHeader();


export const authenticate = async () => {
  localStorage.setItem('lastPage', location.pathname);

  const response = await httpClient.get('/auth/authorization');
  console.log(response.data);
  window.location.href = response.data;
};

export const isAuthenticateAzDevops = async () => {
  const response = await httpClient.get('/auth/autenticate');
  const data = response.data;
  if (!data.authenticated) {
    return false;
  }
  return true;
};

export const setDeafultErrorHandler = () => {
  if (!httpClient) return;

  httpClient.interceptors.response.use(null, defaultErrorHandler);
};

const defaultErrorHandler = (error) => {
  const { status } = error.response;

  if (status === 401) {
    authenticate();
  }
};