import { Grid, LoadingBuzz } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { authenticate, isAuthenticated, setDeafultErrorHandler } from '../api/authorization';
import { setBaseUrl } from '../api/client';
import { useEnvContext } from './EnvProvider';

const AuthenticatedArea = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(isAuthenticated());
  const envs = useEnvContext();

  useEffect(() => {
    if (!envs) return;

    setBaseUrl(envs.BFF_URL);
    setDeafultErrorHandler();

    if (!isAuthed) {
      authenticate()
        .then(() => setIsAuthed(true))
        .catch(console.error);
    }
  }, [isAuthed, envs]);

  if (!isAuthed) {
    return (
      <Grid.Container
        style={{
          rowGap: '1rem',
          padding: '24px 18px',
        }}
      >
        <Grid.Item lg={12} style={{ justifyContent: 'center' }}>
          <LoadingBuzz />
        </Grid.Item>
      </Grid.Container>
    );
  }

  return children;
};

export default AuthenticatedArea;
