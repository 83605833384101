import axios, { AxiosInstance } from 'axios';

export const getAuthHeader = () => localStorage.getItem('authHeader');

export const setBaseUrl = (baseUrl: string) => {
  httpClient.defaults.baseURL = baseUrl;
}

let httpClient: AxiosInstance = axios.create({
  headers: {
    Authorization: getAuthHeader(),
  },
});

export default httpClient;
